var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-secondary my-auto mr-auto"},[_c('i',{class:_vm.$route.meta.iconClass}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"22px","color":"#7f7f7f"}},[_vm._v(" Fiche patrimoine : ")]),(_vm.selectedData)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"25px","color":"#343a40"}},[_vm._v(_vm._s(_vm.selectedData.name))]):_vm._e()]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12"},[_c('modal-actions',{on:{"close":function($event){return _vm.$router.go(
                _vm.$store.getters['routerHistory/fromCreation'] ? -2 : -1
              )}}})],1)])]),_c('div',{staticClass:"shdow"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex flex-wrap justify-content-between"},[_c('p',{staticClass:"h4 text-secondary my-auto mr-auto",staticStyle:{"margin-top":"6.565px !important","margin-bottom":"6.565px !important"}},[_vm._v(" Informations générales ")]),(
              (_vm.isAdmin ||
                _vm.isManager ||
                _vm.isSuper ||
                ((_vm.isPo || _vm.isArchitect) && _vm.isReferent)) &&
              _vm.editMode
            )?_c('div',{staticClass:"m-0 d-flex p-0"},[(_vm.editMode)?_c('b-button',{attrs:{"type":"submit","pill":""},on:{"click":_vm.updateItem}},[_vm._v("Confirmer ")]):_vm._e(),(_vm.editMode)?_c('b-button',{staticStyle:{"background-color":"rgb(173, 181, 189)","border-color":"rgb(173, 181, 189)"},attrs:{"pill":""},on:{"click":function($event){_vm.editMode = !_vm.editMode}}},[_vm._v("Annuler ")]):_c('a',{staticClass:"text-secondary",staticStyle:{"font-size":"30px"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.editMode = !_vm.editMode}}},[_c('i',{staticClass:"mdi mdi-file-document-edit"})])],1):(
              _vm.isAdmin ||
              _vm.isManager ||
              _vm.isPo ||
              _vm.isSuper ||
              (_vm.isArchitect && _vm.isReferent)
            )?_c('a',{staticClass:"text-secondary",staticStyle:{"font-size":"30px","color":"#007bffff !important"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.editMode = !_vm.editMode}}},[_c('i',{staticClass:"mdi mdi-file-document-edit"})]):_vm._e(),_c('hr',{staticClass:"bg-secondary mb-3 mt-1 w-100"})])]),(!_vm.editMode)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Libellé :")]),_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v(" "+_vm._s(_vm.PATRIMOINE.name))])]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Responsable :")]),(_vm.PATRIMOINE.responsable)?_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v(" "+_vm._s(_vm.PATRIMOINE.responsable.firstname + " " + _vm.PATRIMOINE.responsable.lastname))]):_vm._e()]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Description :")]),_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v(" "+_vm._s(_vm.PATRIMOINE.description))])])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Entité :")]),_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v(" "+_vm._s(_vm.PATRIMOINE.organisation && _vm.PATRIMOINE.organisation.entite ? _vm.PATRIMOINE.organisation.entite.libelle : "-")+" ")])]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Organisation :")]),_c('span',{staticClass:"font-weight-bold mr-3"},[_vm._v(" "+_vm._s(_vm.PATRIMOINE.organisation ? _vm.PATRIMOINE.organisation.libelle || "-" : "-"))])])])]):_c('div',{staticClass:"row",attrs:{"id":"add-page"}},[_c('div',{staticClass:"col-md-6 col-12"},[_c('b-form-group',{attrs:{"label-cols":"4","label":"Libellé :","label-class":"font-weight-bold"}},[_c('b-form-input',{class:{
                'is-invalid': _vm.$v.libelle.$error && _vm.$v.libelle.$dirty,
              },attrs:{"type":"text"},model:{value:(_vm.libelle),callback:function ($$v) {_vm.libelle=$$v},expression:"libelle"}}),(_vm.$v.libelle.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}}):_vm._e()],1),_c('b-form-group',{attrs:{"label-cols":"4","label":"Responsable :","label-class":"font-weight-bold"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
                'is-invalid': _vm.$v.selectedCol.$error && _vm.$v.selectedCol.$dirty,
              },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{class:{
                  'is-invalid':
                    _vm.$v.selectedCol.$error && _vm.$v.selectedCol.$dirty,
                },attrs:{"label":"value","options":_vm.responsables,"reduce":(responsable) => responsable.id,"placeholder":"Responsable"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.selectedCol),callback:function ($$v) {_vm.selectedCol=$$v},expression:"selectedCol"}})],1),(_vm.$v.selectedCol.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedCol.required ? "Champ obligatoire" : "")+" ")]):_vm._e()]),_c('b-form-group',{attrs:{"label-cols":"4","label":"Description :","label-class":"font-weight-bold"}},[_c('b-form-textarea',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('div',{staticClass:"col-md-6 col-12"},[_c('b-form-group',{attrs:{"label-cols":"4","label":"Entité :","label-class":"font-weight-bold"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
                'is-invalid':
                  _vm.$v.selectedEntite.$error && _vm.$v.selectedEntite.$dirty,
              },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent",attrs:{"options":_vm.entityList,"label":"text","placeholder":"Entité","reduce":(entite) => entite.value},on:{"input":function($event){_vm.selectedOrganisation = null}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.selectedEntite),callback:function ($$v) {_vm.selectedEntite=$$v},expression:"selectedEntite"}})],1),(_vm.$v.selectedEntite.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedEntite.required ? "Champ obligatoire" : "")+" ")]):_vm._e()]),_c('b-form-group',{attrs:{"label-cols":"4","label":"Organisation :","label-class":"font-weight-bold"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
                'is-invalid':
                  _vm.$v.selectedOrganisation.$error &&
                  _vm.$v.selectedOrganisation.$dirty,
              },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent",class:{
                  'is-invalid':
                    _vm.$v.selectedOrganisation.$error &&
                    _vm.$v.selectedOrganisation.$dirty,
                },attrs:{"label":"text","placeholder":"Organisation","options":_vm.organisations,"reduce":(organisation) => organisation.value},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}]),model:{value:(_vm.selectedOrganisation),callback:function ($$v) {_vm.selectedOrganisation=$$v},expression:"selectedOrganisation"}})],1),(_vm.$v.selectedOrganisation.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedOrganisation.required ? "Champ obligatoire" : "")+" ")]):_vm._e()])],1)])]),_c('br'),_c('hr'),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12"},[_c('b-tabs',{staticClass:"tab-solid tab-solid-primary mt-3",attrs:{"value":_vm.$store.state.tabs.activeTab},on:{"changed":function($event){_vm.activeTab = _vm.$store.state.tabs.activeTab},"activate-tab":(newTabIndex) => _vm.$store.dispatch('tabs/changeTab', newTabIndex)}},[(_vm.isAdmin || _vm.isManager || _vm.isPo || _vm.isSuper)?_c('b-tab',{attrs:{"title":"Référents"}},[_c('patrimony-referent',{attrs:{"items":_vm.referents},on:{"cofirmRole":_vm.confirmUser,"deleteUser":_vm.deleteUser}})],1):_vm._e(),(
              _vm.isAdmin ||
              _vm.isManager ||
              _vm.isPo ||
              _vm.isSuper ||
              _vm.isCollaborator ||
              (_vm.isArchitect && _vm.isReferent)
            )?_c('b-tab',{attrs:{"title":"Portefeuille processus"}},[_c('patrimony-process',{attrs:{"items":_vm.processus,"disabled":!(
                  ((_vm.isPo || _vm.isArchitect) && _vm.isReferent) ||
                  _vm.isAdmin ||
                  _vm.isSuper ||
                  _vm.isManager
                )},on:{"addprocessus":_vm.addprocessus,"deleteProcessus":_vm.deleteProcessus}})],1):_vm._e(),(
              _vm.isAdmin ||
              _vm.isManager ||
              _vm.isPo ||
              _vm.isSuper ||
              _vm.isCollaborator ||
              (_vm.isArchitect && _vm.isReferent)
            )?_c('b-tab',{attrs:{"title":"Portefeuille applications"}},[_c('patrimony-applications',{attrs:{"items":_vm.selectedapplication,"libelle":_vm.PATRIMOINE.name,"disabled":!(
                  ((_vm.isPo || _vm.isArchitect) && _vm.isReferent) ||
                  _vm.isAdmin ||
                  _vm.isSuper ||
                  _vm.isManager
                )}})],1):_vm._e(),(_vm.isAdmin || _vm.isManager || _vm.isPo || _vm.isSuper || _vm.isCollaborator)?_c('b-tab',{attrs:{"title":"Portefeuille composants techniques"}},[_c('ComposantTable',{attrs:{"items":_vm.composant,"libelle":_vm.PATRIMOINE.name,"disabled":!(
                  ((_vm.isPo || _vm.isArchitect) && _vm.isReferent) ||
                  _vm.isAdmin ||
                  _vm.isSuper ||
                  _vm.isManager
                )}})],1):_vm._e(),(_vm.isAdmin || _vm.isManager || _vm.isPo || _vm.isSuper)?_c('b-tab',{attrs:{"title":"Portefeuille cadres d’entreprise"}},[_c('RefCardsTable',{attrs:{"items":_vm.cadres,"libelle":_vm.PATRIMOINE.name}})],1):_vm._e(),(_vm.isAdmin || _vm.isManager || _vm.isPo || _vm.isSuper)?_c('b-tab',{attrs:{"title":"FdR du patrimoine"}},[_c('patrimony-fdr',{attrs:{"libelle":_vm.PATRIMOINE.name}})],1):_vm._e(),(_vm.isAdmin || _vm.isManager || _vm.isPo || _vm.isSuper)?_c('b-tab',{attrs:{"title":"Transformation du patrimoine"}},[_c('patrimony-transformation-map')],1):_vm._e(),(false)?_c('b-tab',{attrs:{"title":"KPI"}},[_c('patrimony-kpi')],1):_vm._e()],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }