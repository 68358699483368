<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="text-secondary my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche patrimoine :
          </span>
          <span
            v-if="selectedData"
            style="font-size: 25px; color: #343a40"
            class="font-weight-bold"
            >{{ selectedData.name }}</span
          >
        </p>

        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div class="row">
          <div class="col-12 d-flex flex-wrap justify-content-between">
            <p
              class="h4 text-secondary my-auto mr-auto"
              style="
                margin-top: 6.565px !important ;
                margin-bottom: 6.565px !important;
              "
            >
              Informations générales
            </p>
            <div
              v-if="
                (isAdmin ||
                  isManager ||
                  isSuper ||
                  ((isPo || isArchitect) && isReferent)) &&
                editMode
              "
              class="m-0 d-flex p-0"
            >
              <b-button type="submit" @click="updateItem" v-if="editMode" pill
                >Confirmer
              </b-button>
              <b-button
                style="
                  background-color: rgb(173, 181, 189);
                  border-color: rgb(173, 181, 189);
                "
                @click="editMode = !editMode"
                v-if="editMode"
                pill
                >Annuler
              </b-button>
              <a
                v-else
                href="#"
                class="text-secondary"
                style="font-size: 30px"
                @click.prevent="editMode = !editMode"
                ><i class="mdi mdi-file-document-edit"></i
              ></a>
            </div>
            <a
              v-else-if="
                isAdmin ||
                isManager ||
                isPo ||
                isSuper ||
                (isArchitect && isReferent)
              "
              href="#"
              class="text-secondary"
              style="font-size: 30px; color: #007bffff !important"
              @click.prevent="editMode = !editMode"
              ><i class="mdi mdi-file-document-edit"></i
            ></a>
            <hr class="bg-secondary mb-3 mt-1 w-100" />
          </div>
        </div>

        <div class="row" v-if="!editMode">
          <div class="col-12 col-md-6">
            <div class="my-4">
              <span>Libellé :</span>
              <span class="font-weight-bold mr-3"> {{ PATRIMOINE.name }}</span>
              <!-- {{ PATRIMOINE }} -->
            </div>
            <div class="my-4">
              <span>Responsable :</span>
              <span v-if="PATRIMOINE.responsable" class="font-weight-bold mr-3">
                {{
                  PATRIMOINE.responsable.firstname +
                  " " +
                  PATRIMOINE.responsable.lastname
                }}</span
              >
            </div>
            <div class="my-4">
              <span>Description :</span>
              <span class="font-weight-bold mr-3">
                {{ PATRIMOINE.description }}</span
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="my-4">
              <span>Entité :</span>
              <span class="font-weight-bold mr-3">
                {{
                  PATRIMOINE.organisation && PATRIMOINE.organisation.entite
                    ? PATRIMOINE.organisation.entite.libelle
                    : "-"
                }}
              </span>
              <!-- {{ PATRIMOINE }} -->
            </div>
            <div class="my-4">
              <span>Organisation :</span>
              <span class="font-weight-bold mr-3">
                {{
                  PATRIMOINE.organisation
                    ? PATRIMOINE.organisation.libelle || "-"
                    : "-"
                }}</span
              >
            </div>
          </div>
        </div>
        <div v-else class="row" id="add-page">
          <!-- First Col -->
          <div class="col-md-6 col-12">
            <b-form-group
              label-cols="4"
              label="Libellé :"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="libelle"
                :class="{
                  'is-invalid': $v.libelle.$error && $v.libelle.$dirty,
                }"
                type="text"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.libelle.$dirty"
              ></span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Responsable :"
              label-class="font-weight-bold"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid': $v.selectedCol.$error && $v.selectedCol.$dirty,
                }"
              >
                <v-select
                  label="value"
                  v-model="selectedCol"
                  :options="responsables"
                  :reduce="(responsable) => responsable.id"
                  placeholder="Responsable"
                  :class="{
                    'is-invalid':
                      $v.selectedCol.$error && $v.selectedCol.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>

              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedCol.$dirty"
              >
                {{ !$v.selectedCol.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Description :"
              label-class="font-weight-bold"
            >
              <b-form-textarea v-model="description"></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-md-6 col-12">
            <b-form-group
              label-cols="4"
              label="Entité :"
              label-class="font-weight-bold"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.selectedEntite.$error && $v.selectedEntite.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  :options="entityList"
                  label="text"
                  placeholder="Entité"
                  v-model="selectedEntite"
                  :reduce="(entite) => entite.value"
                  @input="selectedOrganisation = null"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedEntite.$dirty"
              >
                {{ !$v.selectedEntite.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Organisation :"
              label-class="font-weight-bold"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.selectedOrganisation.$error &&
                    $v.selectedOrganisation.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  label="text"
                  placeholder="Organisation"
                  v-model="selectedOrganisation"
                  :options="organisations"
                  :reduce="(organisation) => organisation.value"
                  :class="{
                    'is-invalid':
                      $v.selectedOrganisation.$error &&
                      $v.selectedOrganisation.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedOrganisation.$dirty"
              >
                {{
                  !$v.selectedOrganisation.required ? "Champ obligatoire" : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="row mt-2">
        <div class="col-12">
          <b-tabs
            :value="$store.state.tabs.activeTab"
            @changed="activeTab = $store.state.tabs.activeTab"
            @activate-tab="
              (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab
              title="Référents"
              v-if="isAdmin || isManager || isPo || isSuper"
            >
              <patrimony-referent
                @cofirmRole="confirmUser"
                @deleteUser="deleteUser"
                :items="referents"
              />
            </b-tab>
            <b-tab
              title="Portefeuille processus"
              v-if="
                isAdmin ||
                isManager ||
                isPo ||
                isSuper ||
                isCollaborator ||
                (isArchitect && isReferent)
              "
            >
              <patrimony-process
                @addprocessus="addprocessus"
                :items="processus"
                @deleteProcessus="deleteProcessus"
                :disabled="
                  !(
                    ((isPo || isArchitect) && isReferent) ||
                    isAdmin ||
                    isSuper ||
                    isManager
                  )
                "
              />
            </b-tab>
            <b-tab
              title="Portefeuille applications"
              v-if="
                isAdmin ||
                isManager ||
                isPo ||
                isSuper ||
                isCollaborator ||
                (isArchitect && isReferent)
              "
            >
              <patrimony-applications
                :items="selectedapplication"
                :libelle="PATRIMOINE.name"
                :disabled="
                  !(
                    ((isPo || isArchitect) && isReferent) ||
                    isAdmin ||
                    isSuper ||
                    isManager
                  )
                "
              />
            </b-tab>
            <b-tab
              title="Portefeuille composants techniques"
              v-if="isAdmin || isManager || isPo || isSuper || isCollaborator"
            >
              <ComposantTable
                :items="composant"
                :libelle="PATRIMOINE.name"
                :disabled="
                  !(
                    ((isPo || isArchitect) && isReferent) ||
                    isAdmin ||
                    isSuper ||
                    isManager
                  )
                "
              />
            </b-tab>
            <b-tab
              title="Portefeuille cadres d’entreprise"
              v-if="isAdmin || isManager || isPo || isSuper"
            >
              <RefCardsTable
                :items="cadres"
                :libelle="PATRIMOINE.name"
              ></RefCardsTable>
            </b-tab>
            <b-tab
              v-if="isAdmin || isManager || isPo || isSuper"
              title="FdR du patrimoine"
            >
              <patrimony-fdr :libelle="PATRIMOINE.name" />
            </b-tab>

            <b-tab
              v-if="isAdmin || isManager || isPo || isSuper"
              title="Transformation du patrimoine"
            >
              <patrimony-transformation-map />
            </b-tab>

            <!-- <b-tab title="KPI" v-if="isAdmin || isManager || isPo || isSuper"> -->
            <b-tab title="KPI" v-if="false">
              <patrimony-kpi />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import PatrimonyApplications from "./components/PatrimonyApplications.vue";
import PatrimonyTransformationMap from "./components/PatrimonyTransformationMap.vue";
import PatrimonyProcess from "./components/PatrimonyProcess.vue";
import PatrimonyReferent from "./components/PatrimonyReferent.vue";
import PatrimonyFdr from "./components/PatrimonyFdr.vue";
import PatrimonyKpi from "./components/PatrimonyKpi.vue";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ComposantTable from "./components/ComposantTable";
import RefCardsTable from "./components/RefCardsTable";

export default {
  components: {
    ComposantTable,
    ModalActions,
    RefCardsTable,
    PatrimonyApplications,
    PatrimonyTransformationMap,
    PatrimonyProcess,
    PatrimonyReferent,
    PatrimonyFdr,
    PatrimonyKpi,
  },
  data: () => ({
    editMode: false,
    composant: [],
    selectedData: null,
    selectedapplication: null,
    selectedCol: null,
    libelle: "",
    description: "",
    referents: null,
    processus: null,
    cadres: null,
    selectedEntite: null,
    selectedOrganisation: null,
  }),
  validations: {
    selectedCol: {
      // initiative
      required,
    },
    libelle: {
      // initiative
      required,
    },
    selectedEntite: {
      required,
    },
    selectedOrganisation: {
      required,
    },
  },
  methods: {
    updateItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitingForm = true;
        var item = {
          id: this.$route.params.id,
          name: this.libelle,
          description: this.description,
          responsable: `/api/users/${this.selectedCol}`,
          organisation: this.selectedOrganisation,
        };
        this.editMode = !this.editMode;
        this.$store.dispatch("patrimoine/updatePatrimoines", item).then(() => {
          this.submitingForm = false;
          Swal.fire("Le patrimoine est mis à jour !", "", "success");
        });
      }
    },
    confirmUser(userId) {
      this.$store.dispatch("patrimoine/createRef", userId).then(() => {
        this.$store.dispatch(
          "patrimoine/fetchReferents",
          this.$route.params.id
        );
      });
    },
    addprocessus(item) {
      this.$store.dispatch("patrimoine/updatePatrimoines", item).then(() => {
        this.$store.dispatch(
          "patrimoine/fetchProcessus",
          this.$route.params.id
        );
      });
    },
    deleteProcessus(item) {
      var data = {
        id: this.$route.params.id,
        processus: item,
      };
      this.$store.dispatch("patrimoine/updatePatrimoines", data).then(() => {
        this.$store.dispatch(
          "patrimoine/fetchProcessus",
          this.$route.params.id
        );
      });
    },
    deleteUser(index) {
      this.$store.dispatch("patrimoine/deleteRef", index).then(() => {
        this.$store.dispatch(
          "patrimoine/fetchReferents",
          this.$route.params.id
        );
      });
    },
  },
  created() {
    this.$store
      .dispatch("patrimoine/fetchPatrimoine", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("patrimoine/fetchReferents", this.$route.params.id);
    this.$store.dispatch("patrimoine/fetchProcessus", this.$route.params.id);
    // this.$store.dispatch("patrimoine/fetchComposants", this.$route.params.id);
    this.$store.dispatch("patrimoine/fetchRef", this.$route.params.id);
    this.$store.dispatch("entity/fetchAllEntities");
  },
  watch: {
    PATRIMOINE() {
      if (this.PATRIMOINE) {
        this.selectedData = this.PATRIMOINE;
        this.libelle = this.PATRIMOINE.name;
        this.selectedCol = this.PATRIMOINE.responsable["id"];
        this.description = this.PATRIMOINE.description;
        this.selectedOrganisation = this.PATRIMOINE.organisation
          ? this.PATRIMOINE.organisation["@id"]
          : null;
        this.selectedEntite =
          this.PATRIMOINE.organisation && this.PATRIMOINE.organisation.entite
            ? this.PATRIMOINE.organisation.entite["@id"]
            : null;
      }
    },
    APPLICATION() {
      this.selectedapplication = this.APPLICATION;
    },
    REFERENTS() {
      this.referents = this.REFERENTS;
    },
    PROCESSUS() {
      this.processus = this.PROCESSUS;
    },
    // COMPOSANT() {
    //   this.composant = this.COMPOSANT.composants;
    // },
    CADRES() {
      this.cadres = this.CADRES.cadres.map((data) => ({
        ...data,
        systemesApplication: {
          systemelength:
            data.applications.length +
            (data.composants ? data.composants.length : 0),
        },
        capaciteTechnique: data.capacites.map((data) => ({
          ...data,
          type: "Technique",
          name: data.name,
          niveau: data.niveau ? data.niveau : "-",
        })),
      }));
    },
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("patrimoine", ["PATRIMOINE"]),
    ...mapGetters("patrimoine", ["REFERENTS"]),
    ...mapGetters("patrimoine", ["PROCESSUS"]),
    ...mapGetters("patrimoine", ["COMPOSANT"]),
    ...mapGetters("patrimoine", ["CADRES"]),
    ...mapGetters("entity", ["ENTITIES"]),

    ...mapGetters([
      "isAdmin",
      "isSuper",
      "isManager",
      "isPo",
      "isArchitect",
      "isCollaborator",
      "AuthenticatedUser",
    ]),

    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));
      return data;
    },
    isReferent() {
      return !!this.REFERENTS.referents.find(
        (user) => user.id === this.AuthenticatedUser.id
      );
    },
    entityList() {
      return this.ENTITIES.filter(
        ({ organisations }) => organisations && organisations.length
      ).map((entite) => ({
        value: entite["@id"],
        text: entite.libelle,
      }));
    },
    organisations() {
      // return this.selectedEntite && this.ENTITIES.length
      //   ? this.ENTITIES.find(
      //       (entite) => entite["@id"] === this.selectedEntite
      //     ).organisations.map((organisation) => ({
      //       value: organisation["@id"],
      //       text: organisation.libelle,
      //     }))
      //   : [];
      return this.selectedEntite
        ? this.isAdmin || this.isSuper
          ? this.ENTITIES.find(
              (entite) => entite["@id"] == this.selectedEntite
            ).organisations.map((organisation) => ({
              value: organisation["@id"],
              text: organisation.libelle,
            }))
          : this.AuthenticatedUser &&
            this.AuthenticatedUser.organisationRatachement
          ? [this.AuthenticatedUser.organisationRatachement].map(
              (organisation) => ({
                value: organisation["@id"],
                text: organisation.libelle,
              })
            )
          : []
        : [];
    },
  },
};
</script>

<style>
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
  height: 100% !important;
  background: white;
}

.vs__search {
  color: #cfcfcf;
}
</style>
